import { Route, Routes, Navigate } from 'react-router-dom';
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import AdminCreateProperty from './pages/AdminCreateProperty';
import Login from './pages/Login';
import Register from './pages/Register';
import AdminHome from './pages/AdminHome';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AdminConversationHistory from './pages/AdminConversationHistory';
import AdminHistory from './pages/AdminHistory';
import Test from './pages/Test';
import 'rsuite/dist/rsuite-no-reset.min.css';
import HouseReport from './pages/HouseReport';
import TestChatbot from './pages/TestChatbot';
import HomeLogin from './home8/Login';
import Properties from './home8/Properties';
import PropertyDetail from './home8/PropertyDetail';
import AdminPropertyDetail from "./pages/AdminPropertyDetail";
import Offer from './home8/Offer';
import OfferIframe from './home8/OfferIframe';
import './index.css'
import OfferSignIframe from './home8/OfferSignIframe';
import OfferViewIframe from './home8/OfferViewIframe';
import AdminOfferReview from "./pages/AdminOfferReview";
import TransactionManagement from './home8/TransactionManagement';
import ListingAgentGuide from './home8/ListingAgentGuide';
import AdminCreatePropertyRedfin from "./pages/AdminCreatePropertyRedfin";

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route
          path="/admin/properties"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminHome />
            //</RequireAuth>
          }
        ></Route>
        <Route
          path="/admin/create"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminCreateProperty />
            //</RequireAuth>
          }
        ></Route>
        <Route
            path="/admin/create-redfin"
            element={
              //<RequireAuth fallbackPath={'/login'}>
              <AdminCreatePropertyRedfin />
              //</RequireAuth>
            }
        ></Route>
        <Route
          path="/admin/history/:propertyId?/:propertyAddr?"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminHistory />
            //</RequireAuth>
          }
        ></Route>
        <Route
            path="/admin/offer_reivew"
            element={
              //<RequireAuth fallbackPath={'/login'}>
              <AdminOfferReview />
              //</RequireAuth>
            }
        ></Route>
        <Route
          path="/property/:propertyId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <PropertyDetail />
            //</RequireAuth>
          }
        />
        <Route
            path="/admin/property/:propertyId"
            element={
              //<RequireAuth fallbackPath={'/login'}>
              <AdminPropertyDetail />
              //</RequireAuth>
            }
        />
        <Route
          path="/admin/test/:propertyId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <Test />
            //</RequireAuth>
          }
        />
        <Route
          path="/admin/conversation/:conversationId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <AdminConversationHistory />
            //</RequireAuth>
          }
        />
        <Route
          path="/house-report/:agentId"
          element={
            //<RequireAuth fallbackPath={'/login'}>
            <HouseReport />
            //</RequireAuth>
          }
        />
        <Route path="/test-chatbot" element={<TestChatbot />} />
        <Route path="/home/all_properties" element={
          <RequireAuth fallbackPath={'/home/login'}>
            <Properties />
          </RequireAuth>
        }></Route>
        <Route path="/home/property/:propertyId" element={
          <RequireAuth fallbackPath={'/home/login'}>
            <PropertyDetail />
          </RequireAuth>
        }></Route>
        <Route path="/home/offers" element={
          <RequireAuth fallbackPath={'/home/login'}>
            <Offer />
          </RequireAuth>
        }></Route>
        <Route path="/home/offer_draft" element={
          <RequireAuth fallbackPath={'/home/login'}>
            <OfferIframe />
          </RequireAuth>
        }></Route>
        <Route path="/home/offer_sign" element={
          <RequireAuth fallbackPath={'/home/login'}>
            <OfferSignIframe />
          </RequireAuth>
        }></Route>
        <Route path="/home/offer_view" element={
          <RequireAuth fallbackPath={'/home/login'}>
            <OfferViewIframe />
          </RequireAuth>
        }></Route>
        <Route path="/home/transaction_management" element={
          <RequireAuth fallbackPath={'/home/login'}>
            <TransactionManagement />
          </RequireAuth>
        }></Route>
        <Route path="/home/la_guide" element={
          <RequireAuth fallbackPath={'/home/login'}>
            <ListingAgentGuide />
          </RequireAuth>
        }></Route>
        <Route path="/home/login" element={<HomeLogin />}></Route>
        {/* Redirect from root path to login path */}
        <Route path="/" element={<Navigate to="/home/login" />} />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
