import { useState } from "react";
import { OFFER_AGENT_API_KEY } from "../utils/constant";
import ChatBottom from "./ChatBottom";
import Collapse from "./Collapse";
import Sidebar from "./Sidebar";
import ReactPlayer from "react-player";
import { ReactComponent as Call } from "../static/Call.svg";
import { ReactComponent as Mail } from "../static/Mail.svg";
import Button, { ButtonType } from "./Button";
import { ReactComponent as Video } from "../static/Video.svg";
import { ReactComponent as Copy } from "../static/Copy.svg";
import {useTranslation} from "react-i18next";

function ListingAgentGuide() {
  const [isCard1Open, setIsCard1Open] = useState(true);
  const [isCard2Open, setIsCard2Open] = useState(true);
  const [isCard3Open, setIsCard3Open] = useState(true);
  const [isCard4Open, setIsCard4Open] = useState(true);
  const [isCard5Open, setIsCard5Open] = useState(true);

  const { t, i18n } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(t('priceNegotiationEmailTemplateValue'));
    alert("Email template copied to clipboard!"); // Optional feedback for users
  };

  return (
    <div className="flex bg-black min-h-full w-full h-full">
      <Sidebar />
      <div className="flex flex-col min-h-screen w-full bg-black">
        <div className="mt-3 mr-3 rounded-[20px] bg-gray-50 grow flex flex-col relative overflow-hidden items-center">
          <div className="w-full flex items-center justify-between py-3 px-6 border-b border-solid border-gray-200">
            <div className="text-xl font-bold">{t('priceNegotiationTitle')}</div>
            <div className="flex gap-2">
              <Button
                Icon={Video}
                type={ButtonType.LIGHT}
                loading={false}
                disabled={false}
                content={t('offerExpertNegotiationSupportLabel')}
                subContent={t('expertOfferNote')}
                onClick={async () => {
                  window.open(
                    `https://calendly.com/home8-support/30min`,
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
          <div className="w-full p-5 overflow-auto flex flex-col items-center">
            <div className="flex flex-col w-[840px] items-center gap-4">
              <Collapse
                title={t('priceNegotiationOverviewTitle')}
                isOpen={isCard1Open}
                onToggle={(value) => setIsCard1Open(value)}
              >
                <ReactPlayer
                  url="https://home8.ai/LA.mp4"
                  width={600}
                  height={300}
                  controls
                />
              </Collapse>
              <Collapse
                title={t('priceNegotiationBeforeOfferTitle')}
                isOpen={isCard2Open}
                onToggle={(value) => setIsCard2Open(value)}
              >
                <ul className="px-5" style={{ listStyleType: "disc" }}>
                  <li className="text-base font-normal">
                    {t('priceNegotiationBeforeOfferBulletPointOne')}
                  </li>
                  <li className="text-base font-normal">
                    {t('priceNegotiationBeforeOfferBulletPointTwo')}
                  </li>
                  <li className="text-base font-normal">
                    {t('priceNegotiationBeforeOfferBulletPointThree')}
                  </li>
                  <li className="text-base font-normal">
                    {t('priceNegotiationBeforeOfferBulletPointFour')}
                  </li>
                </ul>
              </Collapse>
              <Collapse
                title={t('priceNegotiationFirstOfferTitle')}
                isOpen={isCard3Open}
                onToggle={(value) => setIsCard3Open(value)}
              >
                <div className="flex flex-col items-center gap-2">
                  <ul className="px-5" style={{ listStyleType: "disc" }}>
                    <li className="text-base font-normal">
                      {t('priceNegotiationFirstOfferBulletPointOne')}
                    </li>
                  </ul>
                  <div
                      className="w-full border border-dashed border-gray-200 bg-gray-50 rounded-[20px] flex flex-col py-3 px-4 gap-2 text-gray-600">
                    <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Mail/>
                      <div className="font-bold">{t('priceNegotiationFirstOfferEmailTemplateTitle')}</div>
                    </div>
                    <div
                        className="flex items-center space-x-1 text-gray-500 hover:text-gray-700 cursor-pointer"
                        onClick={handleCopy}
                    >
                      <Copy/>
                      <span className="text-sm">复制</span>
                    </div>
                      </div>
                    <div>
                      {t('priceNegotiationEmailTemplateValue')}
                    </div>
                  </div>
                  <div
                      className="w-full border border-dashed border-gray-200 bg-gray-50 rounded-[20px] flex flex-col py-3 px-4 gap-2 text-gray-600">
                    <div className="flex items-center gap-2">
                      <Call/>
                      <div className="font-bold">{t('priceNegotiationFirstOfferCommunicationTitle')}</div>
                    </div>
                    <ul className="px-5" style={{ listStyleType: "disc" }}>
                      <li className="text-base font-normal">
                        I am representing myself and I am an experienced buyer.
                        (I already bought several houses before)
                      </li>
                      <li className="text-base font-normal">
                        Deposit funds are available and could be deposited
                        within 1 business day after entering the contract
                      </li>
                      <li className="text-base font-normal">
                        I have my own experienced transaction coordinator who
                        could ensure the delivery of all necessary documents
                      </li>
                      <li className="text-base font-normal">
                        You can talk with my loan agent to ensure the
                        qualifications and amount of my loan
                      </li>
                      <li className="text-base font-normal">
                        No commission needed from the seller's side
                      </li>
                    </ul>
                  </div>
                </div>
              </Collapse>
              <Collapse
                title={t('priceNegotiationAfterOfferTitle')}
                isOpen={isCard4Open}
                onToggle={(value) => setIsCard4Open(value)}
              >
                <ul className="px-5" style={{ listStyleType: "disc" }}>
                  <li className="text-base font-normal">
                    {t('priceNegotiationAfterOfferBulletPointOne')}
                  </li>
                  <li className="text-base font-normal">
                    {t('priceNegotiationAfterOfferBulletPointTwo')}
                  </li>
                  <li className="text-base font-normal">
                    {t('priceNegotiationAfterOfferBulletPointThree')}
                  </li>
                </ul>
              </Collapse>
              <Collapse
                title={t('priceNegotiationOfferResultTitle')}
                isOpen={isCard5Open}
                onToggle={(value) => setIsCard5Open(value)}
              >
                <div className="flex flex-col items-center gap-2">
                  <ul className="px-5" style={{ listStyleType: "disc" }}>
                    <li className="text-base font-normal">
                      {t('priceNegotiationOfferResultBulletPointOne')}
                    </li>
                    <li className="text-base font-normal">
                      {t('priceNegotiationOfferResultBulletPointTwo')}
                    </li>
                    <li className="text-base font-normal">
                      {t('priceNegotiationOfferResultBulletPointThree')}
                    </li>
                  </ul>
                  <div className="w-full border border-dashed border-gray-200 bg-gray-50 rounded-[20px] flex flex-col py-3 px-4 gap-2 text-gray-600">
                    <div className="flex items-center gap-2">
                      <Call />
                      <div className="font-bold">
                        {t('priceNegotiationOfferResultDeclineTitle')}
                      </div>
                    </div>
                    <ul className="px-5" style={{ listStyleType: "disc" }}>
                      <li className="text-base font-normal">
                        If they received my offer?{" "}
                      </li>
                      <li className="text-base font-normal">
                        If the price and terms are acceptable?
                      </li>
                      <li className="text-base font-normal">
                        Where is my offer currently stands and, if it’s not the
                        highest, whether I will have another opportunity to
                        improve it?
                      </li>
                    </ul>
                  </div>
                  <div className="w-full border border-dashed border-gray-200 bg-gray-50 rounded-[20px] flex flex-col py-3 px-4 gap-2 text-gray-600">
                    <div className="flex items-center gap-2">
                      <Call />
                      <div className="font-bold">
                        {t('priceNegotiationOfferResultNoResponseTitle')}
                      </div>
                    </div>
                    <ul className="px-5" style={{ listStyleType: "disc" }}>
                      <li className="text-base font-normal">
                        Is there any flexibility?
                      </li>
                      <li className="text-base font-normal">
                        If I can resubmit my offer, what price or terms would be
                        acceptable?
                      </li>
                      <li className="text-base font-normal">
                        Where is my offer currently stands and, if it’s not the
                        highest, whether I will have another opportunity to
                        improve it?
                      </li>
                    </ul>
                  </div>
                  <div className="w-full border border-dashed border-gray-200 bg-gray-50 rounded-[20px] flex flex-col py-3 px-4 gap-2 text-gray-600">
                    <div className="font-bold">
                      {t('priceNegotiationOfferResultInclineAcceptTitle')}
                    </div>
                    <ul className="px-5" style={{ listStyleType: "disc" }}>
                      <li className="text-base font-normal">
                        {t('priceNegotiationOfferResultInclineAcceptBulletPointOne')}
                      </li>
                      <li className="text-base font-normal">
                        {t('priceNegotiationOfferResultInclineAcceptBulletPointTwo')}
                      </li>
                    </ul>
                  </div>
                  <div className="w-full border border-dashed border-gray-200 bg-gray-50 rounded-[20px] flex flex-col py-3 px-4 gap-2 text-gray-600">
                    <div className="font-bold">
                      {t('priceNegotiationOfferResultNoResultWithinOneDayTitle')}
                    </div>
                    <ul className="px-5" style={{ listStyleType: "disc" }}>
                      <li className="text-base font-normal">
                        {t('priceNegotiationOfferResultNoResultWithinOneDayBulletPointOne')}
                      </li>
                      <li className="text-base font-normal">
                        {t('priceNegotiationOfferResultNoResultWithinOneDayBulletPointTwo')}
                      </li>
                    </ul>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
        <ChatBottom disableAI={false} agentApiKey={OFFER_AGENT_API_KEY} />
      </div>
    </div>
  );
}

export default ListingAgentGuide;
