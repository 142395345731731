import { OFFER_AGENT_API_KEY } from "../utils/constant";
import ChatBottom from "./ChatBottom";
import Sidebar from "./Sidebar";
import Table, { TableData } from "./Table";

const tableData: TableData = {
  headers: [
    {
      title: "transactionManagementBasicService",
      subTitle: "transactionManagementBasicServicePrice",
    },
    {
      title: "transactionManagementAdvancedService",
      subTitle: "transactionManagementAdvancedServicePrice",
    },
    {
      title: "transactionManagementThirdPartyService",
      subTitle: "transactionManagementThirdPartyServicePrice",
    },
  ],
  tableData: [
    {
      rowName: "transactionManagementAgentSupport",
      data: [
        "transactionManagementAgentSupportBasic",
        "transactionManagementAgentSupportAdvanced",
        "transactionManagementAgentSupportThirdParty",
      ],
    },
    {
      rowName: "transactionManagementAgentInstruction",
      data: [
        "transactionManagementAgentInstructionBasic",
        "transactionManagementAgentInstructionAdvanced",
        "transactionManagementAgentInstructionThirdParty",
      ],
    },
    {
      rowName: "transactionManagementLenderSign",
      data: [
        "transactionManagementLenderSignBasic",
        "transactionManagementLenderSignAdvanced",
        "transactionManagementLenderSignThirdParty",
      ],
    },
    {
      rowName: "transactionManagementGrantDeed",
      data: [
        "transactionManagementGrantDeedBasic",
        "transactionManagementGrantDeedAdvanced",
        "transactionManagementGrantDeedThirdParty",
      ],
    },
    {
      rowName: "transactionManagementAgentAssist",
      data: [
        "transactionManagementAgentAssistBasic",
        "transactionManagementAgentAssistAdvanced",
        "transactionManagementAgentAssistThirdParty",
      ],
    },
    {
      rowName: "transactionManagementAgentInspect",
      data: [
        "transactionManagementAgentInspectBasic",
        "transactionManagementAgentInspectAdvanced",
        "transactionManagementAgentInspectThirdParty",
      ],
    },
    {
      rowName: "transactionManagementAgentPostSale",
      data: [
        "transactionManagementAgentPostSaleBasic",
        "transactionManagementAgentPostSaleAdvanced",
        "transactionManagementAgentPostSaleThirdParty",
      ],
    },
  ],
};

function TransactionManagement() {
  return (
    <div className="flex bg-black min-h-full w-full h-full">
      <Sidebar />
      <div className="flex flex-col min-h-screen w-full bg-black">
        <div className="mt-3 mr-3 rounded-[20px] bg-gray-50 grow flex flex-col relative overflow-hidden">
          <div className="p-5 overflow-auto">
            <Table tableData={tableData} />
          </div>
        </div>
        <ChatBottom disableAI={false} agentApiKey={OFFER_AGENT_API_KEY} />
      </div>
    </div>
  );
}

export default TransactionManagement;
